.header {
    position: fixed;
    width: 100%;
    background-color: #3d7ad3;
    height: 10vh;
    max-height: 90px;
    top: 0px;
    color: white;
    padding: 10px;
    -moz-box-shadow:    3px 3px 5px 3px #C0C0C0;
    -webkit-box-shadow: 3px 3px 5px 3px #C0C0C0;
    box-shadow:         3px 3px 5px 3px #C0C0C0;
    z-index: 10;
}
.login {
    padding-top: 10vh;
}
.login-card {
    border-radius: 5px;
    padding: 20px;
    margin-top: 30px;
    background: #ffffff;
    box-shadow: 1px 2px 10px 1px #ccc;
}
.submit-login {
    width: 100%;
    border-radius: 6px;
    border: none;
    background-color: #3d7ad3;
    color: #ffffff;
    font-weight: 600;
    padding: 10px 0px;
}
.submit-login:hover {
    background-color: #044cb8;
    transition: .1s;
}