.modal {
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  z-index: 100;
  padding-top: 40px;

  & .modal-dialog {
    &.modal-x-lg {
      width: 90% !important;
    }
  }

  &.in {
    display: block;
  }

  & .modal-body {
    max-height: 470px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 10px;
  }

  & .form-modal-container {
    display: flex;
    flex-wrap: wrap;

    & .form-group {
      flex: 1;
      flex-basis: 50%;
      padding: 0px 5px;

      &.full-width {
        flex-basis: 100%;
      }
    }
  }
}